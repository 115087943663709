import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import uk from 'vuetify/src/locale/uk';

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: { uk },
    current: 'uk',
  },
  theme: {
    themes: {
      light: {
        primary: '#00359c',
        secondary: '#81a9e1',
        accent: '#f0a21d',
        error: '#e75f00',
      },
    },
  },
});
