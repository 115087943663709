















import Vue from 'vue';
import TelegramAuthButton from '@/components/TelegramAuthButton.vue';

export default Vue.extend({
  components: {
    TelegramAuthButton,
  },
});
