import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import axios from 'axios';
import { RootState, User } from '@/store/types';

interface AuthState {
  isReady: boolean;
  user?: User;
}

export default class AuthModule implements Module<AuthState, RootState> {
  public readonly namespaced: boolean = true;

  public readonly state: AuthState = {
    isReady: false,
    user: undefined,
  };

  public readonly getters: GetterTree<AuthState, RootState> = {
    isGuest: (state): boolean => state.isReady && state.user === undefined,
    isAuth: (state): boolean => state.isReady && state.user !== undefined,
    isReady: (state): boolean => state.isReady,
    user: (state): User | undefined => state.user,
  };

  public readonly actions: ActionTree<AuthState, RootState> = {
    load({ commit }): Promise<void> {
      // commit('SET_LOADING', true);
      return axios
        .get<User>(`/auth/me`)
        .then(({ data }) => {
          commit('SET_USER', data);
        })
        .catch(err => {
          if (err?.status == 401) {
            // TODO if 401
            commit('SET_GUEST');
          } else {
            // TODO if error
            console.error(err);
          }
        })
        .finally(() => {
          commit('SET_READY');
          // commit('SET_LOADING', false);
        });
    },
    logout({ commit }): Promise<boolean> {
      return axios
        .post<boolean>(`/auth/logout`)
        .then(({ data }): boolean => {
          if (data) {
            commit('SET_GUEST');
            return true;
          } else {
            console.error('Error during logout ...', data);
            return false;
          }
        })
        .catch(err => {
          if (err?.status == 401) {
            commit('SET_GUEST');
            return true;
          } else {
            // TODO if error
            console.error('Error during logout ...', err);
            return false;
          }
        });
    },
  };

  public readonly mutations: MutationTree<AuthState> = {
    SET_USER(state, user: User) {
      console.debug(user);
      state.user = user;
    },
    SET_GUEST(state) {
      state.user = undefined;
    },
    SET_READY(state) {
      state.isReady = true;
    },
  };
}
