import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import axios from 'axios';
import { RootState } from '@/store/types';

interface Chat {
  readonly id: number;
  readonly name: string;
  readonly photoUrl?: string;
}

interface ChatState {
  isLoading: boolean;
  list?: ReadonlyArray<Chat>;
}

export default class AuthModule implements Module<ChatState, RootState> {
  public readonly namespaced: boolean = true;

  public readonly state: ChatState = {
    isLoading: false,
    list: undefined,
  };

  public readonly getters: GetterTree<ChatState, RootState> = {
    list: (state): ReadonlyArray<Readonly<Chat>> => state.list ?? [],
    isLoaded: (state): boolean => state.list !== undefined,
    isLoading: (state): boolean => state.isLoading,
  };

  public readonly mutations: MutationTree<ChatState> = {
    SET_CHATS(state, chats: ReadonlyArray<Chat>): void {
      state.list = chats ?? [];
    },

    SET_LOADING(state, loading: boolean): void {
      state.isLoading = loading;
    },
  };

  public readonly actions: ActionTree<ChatState, RootState> = {
    load: async ({ commit }): Promise<void> => {
      commit('SET_LOADING', true);
      await axios
        .get<{
          items: ReadonlyArray<Chat>;
        }>('/chat/list')
        .then(({ data }) => {
          commit('SET_CHATS', data?.items);
        })
        .finally(() => {
          commit('SET_LOADING', false);
        });
    },
  };
}
