












import Vue from 'vue';
// import TelegramAuthButton from '@/components/TelegramAuthButton.vue';
import ChatList from '@/components/ChatList.vue';
import LinkedAccounts from '@/components/LinkedAccounts.vue';

export default Vue.extend({
  components: {
    ChatList,
    LinkedAccounts,
  },
});
