



































import Vue from 'vue';

export default Vue.extend({
  mounted(): void {
    this.$store.dispatch('chat/load');
  },
  computed: {
    headers(): ReadonlyArray<unknown> {
      return [
        {
          // text: 'ID',
          value: 'photo',
          align: 'center',
          sortable: false,
        },
        // {
        //   text: 'ID',
        //   value: 'id',
        //   align: 'center',
        // },
        {
          text: 'Назва',
          value: 'name',
        },
        {
          text: 'Дії',
          value: 'actions',
          align: 'center',
          sortable: false,
        },
        // {
        //   text: 'Photo URL',
        //   value: 'photoUrl',
        //   sotrable: false,
        // },
      ];
    },
  },
  methods: {
    avatarName(name: string): string {
      return name
        ?.split(/\s/)
        ?.map(word => word?.substring(0, 1))
        ?.splice(0, 2)
        ?.join('');
    },
  },
});
