




import Vue, { PropType } from 'vue';

export default Vue.extend({
  props: {
    version: {
      type: Number,
      default: 14,
    },
    login: {
      type: String,
      required: true,
    },
    size: {
      type: String as PropType<'large' | 'medium' | 'small'>,
      default: 'large',
    },
    showUserPhoto: {
      type: Boolean,
      default: false,
    },
    authUrl: {
      type: String,
      required: true,
    },
    authCallback: {
      type: String,
      required: false,
    },
    requestWriteAccess: {
      type: Boolean,
      default: false,
    },
    radius: {
      type: Number,
      required: false,
    },
  },
  computed: {
    script(): HTMLScriptElement {
      const script: HTMLScriptElement = document.createElement('script');
      script.async = true;
      script.src = `https://telegram.org/js/telegram-widget.js?` + this.version;
      script.setAttribute('data-telegram-login', this.login);
      script.setAttribute('data-size', this.size);
      this.radius && script.setAttribute('data-radius', this.radius.toString());
      this.requestWriteAccess &&
        script.setAttribute('data-request-access', 'write');
      this.authUrl && script.setAttribute('data-auth-url', this.authUrl);
      this.authCallback &&
        script.setAttribute('data-onauth', this.authCallback);
      return script;
    },
  },
  mounted(): void {
    const script = this.script;
    (this.$refs.placeholder as HTMLElement)?.replaceWith(script);
  },
});
