import Vue from 'vue';
import VueRouter, { RouteConfig, NavigationGuard } from 'vue-router';
import store from '@/store';
import GuestView from '@/views/Guest.vue';
import ProfileView from '@/views/Profile.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: GuestView,
    meta: {
      title: 'Вхід до системи',
    },
  },
  {
    path: '/profile',
    name: 'Profile',
    component: ProfileView,
    meta: {
      title: 'Особистий кабінет',
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

const authGuard: NavigationGuard = (to, from, next) => {
  console.debug(to, from, next);

  if (to?.meta?.title) {
    document.title = to.meta.title;
  } else {
    // set default title?
    document.title = 'Сторінка Кібер-охоронця';
  }

  if (
    store.getters.isAuth &&
    to.matched.some(record => record.name == 'Home')
  ) {
    next({
      name: 'Profile',
    });
  } else if (
    store.getters.isGuest &&
    to.matched.some(record => record.name == 'Profile')
  ) {
    next({
      name: 'Home',
    });
  } else {
    next();
  }
};

router.beforeEach((to, from, next) => {
  if (store.getters['isReady']) {
    authGuard(to, from, next);
  } else {
    const unsubscribe = store.subscribeAction({
      after: () => {
        if (store.getters['isReady']) {
          unsubscribe();
          authGuard(to, from, next);
        }
      },
    });
  }
});

export default router;
