


































import Vue from 'vue';

export default Vue.extend({
  mounted(): void {
    this.$store.dispatch('init');
  },
  methods: {
    logout(): Promise<void> {
      // console.error('logout');
      return this.$store.dispatch('auth/logout').then(() => {
        this.$router.push({
          name: 'Home',
        });
      });
    },
  },
});
