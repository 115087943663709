










































import Vue from 'vue';
import axios from 'axios';

type LinkedAccount = {
  source: string;
  sourceName: string;
  email?: string;
};

export default Vue.extend({
  data(): {
    isLoading: boolean;
    readonly list: Array<LinkedAccount>;
  } {
    return {
      isLoading: false,
      list: [],
    };
  },
  mounted(): void {
    this.load();
  },
  computed: {
    ukmaLinked(): LinkedAccount | undefined {
      return this.list?.find(linked => linked.source === 'ukma');
    },
  },
  watch: {
    list(value) {
      console.debug(value);
    },
  },
  methods: {
    async load(): Promise<void> {
      this.isLoading = true;
      axios
        .get<ReadonlyArray<LinkedAccount>>('/auth/linked')
        .then(({ data }) => {
          this.list.length = 0;
          this.list.push(...data);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
});
