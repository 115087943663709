import Vue from 'vue';
import Vuex from 'vuex';
// import axios from 'axios';
// import { User, RootState } from '@/store/types';
import { RootState } from '@/store/types';
import AuthModule from '@/store/modules/auth.module';
import ChatModule from '@/store/modules/chat.module';

Vue.use(Vuex);

export default new Vuex.Store<RootState>({
  state: {
    isLoading: false,
    isReady: false,
    // user: undefined,
  },
  getters: {
    // isGuest: (state): boolean => state.isReady && state.user === undefined,
    // isAuth: (state): boolean => state.isReady && state.user !== undefined,
    isGuest: (state, getters, rootState, rootGetters): boolean =>
      rootGetters['auth/isGuest'],
    isAuth: (state, getters, rootState, rootGetters): boolean =>
      rootGetters['auth/isAuth'],
    isReady: (state): boolean => state.isReady,
  },
  mutations: {
    SET_LOADING(state, loading: boolean) {
      state.isLoading = !!loading;
    },
    // SET_USER(state, user: User) {
    //   console.debug(user);
    //   state.user = user;
    // },
    // SET_GUEST(state) {
    //   state.user = undefined;
    // },
    SET_READY(state) {
      state.isReady = true;
    },
  },
  actions: {
    setLoading({ commit }, loading: boolean): void {
      commit('SET_LOADING', loading);
    },
    async init({ commit, dispatch }): Promise<void> {
      commit('SET_LOADING', true);
      await dispatch('auth/load', null, { root: true })
        // .then()
        // .catch()
        .finally(() => {
          commit('SET_READY');
          commit('SET_LOADING', false);
        });

      // await axios
      //   .get<User>(`/auth/me`)
      //   .then(({ data }) => {
      //     commit('SET_USER', data);
      //   })
      //   .catch(err => {
      //     if (err?.status == 401) {
      //       // TODO if 401
      //       commit('SET_GUEST');
      //     } else {
      //       // TODO if error
      //       console.error(err);
      //     }
      //   })
      //   .finally(() => {
      //     commit('SET_READY');
      //     commit('SET_LOADING', false);
      //   });
    },
  },
  modules: {
    auth: new AuthModule(),
    chat: new ChatModule(),
  },
});
